import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const saveURL = `${environment.apiUrl}/api/MainPage/Save`;
const getURL = `${environment.apiUrl}/api/MainPage/Get`;

@Injectable()
export class MainPageService {
    constructor(private http: HttpClient) {
    }

    public save(page): Observable<any> {
        return this.http.post(saveURL, page).pipe(share());
    }

    public get(): Observable<any> {
        return this.http.get(getURL).pipe(share());
    }
}
