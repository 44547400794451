export const SiteDictionaryConsts = {
        mainPageTitle: '_MAIN_PAGE_TITLE_',
        mainPageAbstract: '_MAIN_PAGE_ABSTRACT_',
        mainPageAbstractTitle: '_MAIN_PAGE_ABSTRACT_TITLE_',
        mainPagePicture: '_MAIN_PAGE_PICTURE_',
        menuLanguageTitle: '_MENU_LANGUAGE_TITLE_',

        ContactPageTelephoneHTMLContent: '_CONTACT_PAGE_TELEPHONE_HTML_CONTENT_',
        ContactPageAddressHTMLContent: '_CONTACT_PAGE_ADDRESS_HTML_CONTENT_',
        ContactPageTelephoneTitle: '_CONTACT_PAGE_TELEPHONE_TITLE_',
        ContactPageAddressTitle: '_CONTACT_PAGE_ADDRESS_TITLE_',
        ContactPageButtonTitle: '_CONTACT_PAGE_BUTTON_TITLE_',
        ContactPageMessagePlaceHolder: '_CONTACT_PAGE_MESSAGE_PLACE_HOLDER_',
        ContactPageTelephoneNumberPlaceHolder: '_CONTACT_PAGE_TELEPHONE_NUMBER_PLACE_HOLDER_',
        ContactPageEMailPlaceHolder: '_CONTACT_PAGE_E_MAIL_PLACE_HOLDER_',
        ContactPageNameSurnamePlaceHolder: '_CONTACT_PAGE_NAME_SURNAME_PLACE_HOLDER_',
        ContactPageSubTitle: '_CONTACT_PAGE_SUB_TITLE_',
        ContactPageTitle: '_CONTACT_PAGE_TITLE_',
        ContactPageLatitude: '_CONTACT_PAGE_LATITUDE_',
        ContactPageLongitude: '_CONTACT_PAGE_LONGITUDE_',
        ContactPageValidateMessageTextContent: '_CONTACT_PAGE_VALIDATE_MESSAGE_TEXT_CONTENT_',

        SearchPageTitle: '_SEARCH_PAGE_TITLE_',
        SearchPageNoContentText: '_SEARCH_PAGE_NO_CONTENT_TEXT_',
        SearchPlaceHolder: '_SEARCH_PAGE_SEARCH_PLACEHOLDER_',
}

