import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResponseModel } from '../models/response.model';
import { SearchPageModel } from '../models/search-page.model';

const getSearchPageModelURL = `${environment.apiUrl}/api/SearchPage/GetSearchPageModel`;
const saveSearchPageModelURL = `${environment.apiUrl}/api/SearchPage/SaveSearchPageModel`;

@Injectable()
export class SearchService {
  constructor(private http: HttpClient) {
  }

  getSearchPageModel(): Observable<ResponseModel<SearchPageModel>> {
    return this.http.get<ResponseModel<SearchPageModel>>(getSearchPageModelURL).pipe(share());
  }

  saveSearchPageModel(searchPageModel: SearchPageModel): Observable<any> {
    return this.http.post(saveSearchPageModelURL, searchPageModel).pipe(share());
  }
}
