import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LanguageModel } from '../models/language.model';
import { ResponseModel } from '../models/response.model';
import { FileModel } from '../models/FileModel';

const getfilesURL = `${environment.apiUrl}/api/File/GetFiles`;
const saveFileURL = `${environment.apiUrl}/api/File/Save`;
const getFileURL = `${environment.apiUrl}/api/File/Get`;
const deleteFileURL = `${environment.apiUrl}/api/File/Delete/`;

@Injectable()
export class FileService {
  constructor(private http: HttpClient) {
  }

  public getFiles(): Observable<ResponseModel<FileModel[]>> {
    return this.http.get<ResponseModel<FileModel[]>>(getfilesURL).pipe(share());
  }

  public get(id: string): Observable<ResponseModel<FileModel>> {
    return this.http.get<ResponseModel<FileModel>>(getFileURL + '/' + id).pipe(share());
  }

  public delete(id: string): Observable<any> {
    return this.http.get(deleteFileURL + id).pipe(share());
  }

  public save(file: File): Observable<any> {
    const form = new FormData();

    form.append('file', file);

    return this.http.post(saveFileURL, form).pipe(share());
  }
}
