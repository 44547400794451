import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResponseModel } from '../models/response.model';
import { MenuTreeViewModel } from '../models/menu/menu-tree-view.model';

const addURL = `${environment.apiUrl}/api/menu/AddMenu`;
const getMenuURL = `${environment.apiUrl}/api/menu/GetMenu/`;
const getMenuListURL = `${environment.apiUrl}/api/menu/GetMenuList`;
const deleteMenuURL = `${environment.apiUrl}/api/menu/DeleteMenu/`;
const changePriorityURL = `${environment.apiUrl}/api/menu/ChangePriority/`;

@Injectable()
export class MenuService {

    constructor(private http: HttpClient) {
    }

    public add(menu): Observable<any> {
        return this.http.post(addURL, menu).pipe(share());
    }

    public get(id): Observable<any> {
        return this.http.get(getMenuURL + id).pipe(share());
    }

    public getAll(): Observable<ResponseModel<MenuTreeViewModel[]>> {
        return this.http.get<ResponseModel<MenuTreeViewModel[]>>(getMenuListURL).pipe(share());
    }

    public delete(menu: MenuTreeViewModel): Observable<any> {
        return this.http.post(deleteMenuURL, { id: menu.id, rowVersion: menu.rowVersion }).pipe(share());
    }

    public changePriority(id, step): Observable<any> {
        return this.http.get(changePriorityURL + id + '/' + step).pipe(share());
    }
}
