import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ResponseModel } from '../models/response.model';
import { ContactPageModel } from '../models/contact-page/contact-page.model';
import { ContactFormRequestModel } from '../../website/model/contact-form-request.model';
import { QueryParamsModel } from '../../core/models/query-params.model';
import { QueryResultsModel } from '../content/pages/components/apps/e-commerce/_core/models/query-models/query-results.model';

const getContactFormModelListURL = `${environment.apiUrl}/api/ContactPage/GetContactFormModelList`;
const getContactPageModelURL = `${environment.apiUrl}/api/ContactPage/GetContactPageModel`;
const saveContactPageModelURL = `${environment.apiUrl}/api/ContactPage/SaveContactPageModel`;

@Injectable()
export class ContactService {
  constructor(private http: HttpClient) {
  }

  getContactFormModelList(queryParams: QueryParamsModel)
    : Observable<ResponseModel<QueryResultsModel<ContactFormRequestModel[]>>> {
    return this.http.post<ResponseModel<QueryResultsModel<ContactFormRequestModel[]>>>
    (getContactFormModelListURL, queryParams).pipe(share());
  }

  getContactPageModel(): Observable<ResponseModel<ContactPageModel>> {
    return this.http.get<ResponseModel<ContactPageModel>>(getContactPageModelURL).pipe(share());
  }

  saveContactPageModel(contactPageModel: ContactPageModel): Observable<any> {
    return this.http.post(saveContactPageModelURL, contactPageModel).pipe(share());
  }
}
